import type {
    Dispatch,
    FocusEvent,
    KeyboardEvent,
    MouseEvent,
    Ref,
    RefObject,
    SetStateAction,
} from 'react';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useSearchStore } from '@/stores/searchStore';

import type { SearchProps } from './Search';
import useOnClickOutside from './Search.hooks';

interface SearchContextProps extends SearchProps {
    wrapperRef: RefObject<HTMLDivElement>;
}

export type UseSearchContextProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selected: string | null;
    observer?: IntersectionObserver;
    inputRef?: Ref<HTMLInputElement>;
    wrapperRef?: Ref<HTMLDivElement>;
    onBlur: (
        event: FocusEvent<HTMLInputElement>,
    ) => Promise<FocusEvent<HTMLInputElement>>;
    onFocus: (
        event: FocusEvent<HTMLInputElement>,
    ) => Promise<FocusEvent<HTMLInputElement>>;
    onClick: (event: MouseEvent<HTMLLIElement>) => void;
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    setSelected: Dispatch<SetStateAction<string | null>>;
};

const Context = createContext<UseSearchContextProps>({
    open: false,
    selected: null,
    observer: undefined,
    inputRef: undefined,
    wrapperRef: undefined,
    onBlur: (event: FocusEvent<HTMLInputElement>) => Promise.resolve(event),
    onFocus: (event: FocusEvent<HTMLInputElement>) => Promise.resolve(event),
    onClick: () => null,
    onKeyDown: () => null,
    setSelected: () => null,
    setOpen: () => null,
});

const Provider = ({
    onChange = () => null,
    onSearch,
    wrapperRef,
    children,
}: SearchContextProps) => {
    const [selected, setSelected] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [open, setOpen] = useState(false);

    const searchFocus = useSearchStore((state) => state.searchFocus);
    const setSearchFocus = useSearchStore((state) => state.setSearchFocus);

    const callback = (nodes: IntersectionObserverEntry[]) =>
        nodes.forEach(
            (node) =>
                !node.isIntersecting &&
                node.target.scrollIntoView({
                    block: 'nearest',
                }),
        );

    const [observer, setObserver] = useState<IntersectionObserver>();

    useEffect(() => {
        setObserver(
            new IntersectionObserver(callback, {
                root: wrapperRef?.current,
                rootMargin: '40px 0px 0px 0px',
                threshold: 1,
            }),
        );
    }, [wrapperRef]);

    const onBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) =>
            new Promise<FocusEvent<HTMLInputElement>>((resolve) => {
                const target = event.currentTarget;
                target.blur();
                setOpen(false);
                setTimeout(() => {
                    inputRef.current?.blur();
                }, 100);
                resolve(event);
            }),
        [setOpen],
    );

    const onFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) =>
            new Promise<FocusEvent<HTMLInputElement>>((resolve) => {
                setOpen(true);
                resolve(event);
            }),
        [setOpen],
    );

    useEffect(() => {
        setOpen(searchFocus);
    }, [searchFocus, setOpen]);

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (!wrapperRef?.current) {
                return;
            }

            const nodes = wrapperRef?.current.querySelectorAll(
                '*[role="option"]:not([aria-disabled="true"])',
            );

            const data = Array.from(nodes).map((el) =>
                (el as Element).getAttribute('value'),
            );

            switch (event.key) {
                case 'ArrowDown': {
                    event.preventDefault();
                    event.stopPropagation();

                    const current = data.indexOf(selected);

                    setSelected(data[Math.min(data.length - 1, current + 1)]);

                    break;
                }
                case 'PageDown': {
                    event.preventDefault();
                    event.stopPropagation();

                    const current = data.indexOf(selected);

                    setSelected(data[Math.min(data.length - 1, current + 5)]);

                    break;
                }
                case 'End': {
                    event.preventDefault();
                    event.stopPropagation();

                    setSelected(data[data.length - 1]);

                    break;
                }

                case 'ArrowUp': {
                    event.preventDefault();
                    event.stopPropagation();

                    const current = data.indexOf(selected);
                    if (current > 0) {
                        setSelected(data[Math.max(-1, current - 1)]);
                    } else {
                        setSelected(null);
                    }
                    break;
                }

                case 'PageUp': {
                    event.preventDefault();
                    event.stopPropagation();

                    const current = data.indexOf(selected);

                    setSelected(data[Math.max(0, current - 5)]);
                    break;
                }

                case 'Home': {
                    event.preventDefault();
                    event.stopPropagation();
                    setSelected(data[0]);
                    break;
                }

                case 'Escape': {
                    event.preventDefault();
                    event.stopPropagation();
                    (event.target as HTMLElement).blur();
                    break;
                }

                case 'Enter': {
                    event.preventDefault();
                    event.stopPropagation();

                    const input = event.target as HTMLInputElement;

                    if (input.value.length < 1) {
                        return;
                    }

                    if (selected && data.includes(selected)) {
                        onSearch(selected);
                    } else {
                        setSelected(null);
                        onSearch(input.value);
                    }

                    input.blur();

                    setOpen(false);
                    setSearchFocus(false);

                    break;
                }

                default:
                    break;
            }
        },
        [selected, wrapperRef, onSearch, setOpen, setSearchFocus],
    );

    useEffect(() => {
        if (onChange) {
            onChange(selected);
        }
    }, [onChange, selected]);

    const onClick = useCallback(
        (event: MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const value = event.currentTarget.getAttribute('value');

            if (value) {
                onSearch(value);
            }

            (document.activeElement as HTMLElement)?.blur();
        },
        [onSearch],
    );

    const value = useMemo(
        () => ({
            observer,
            inputRef,
            wrapperRef,
            selected,
            setSelected,
            onBlur,
            onFocus,
            onClick,
            onKeyDown,
            open,
            setOpen,
        }),
        [
            observer,
            inputRef,
            wrapperRef,
            selected,
            setSelected,
            onBlur,
            onFocus,
            onClick,
            onKeyDown,
            open,
            setOpen,
        ],
    );

    useOnClickOutside(wrapperRef, () => setOpen(false), value);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSearch = () => {
    const context = useContext(Context);

    if (context === undefined) throw new Error('No context found');

    return context;
};

export default Provider;
